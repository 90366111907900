<template>
  <div>
    <b-card class="min-vh-100">
      <b-row>
        <b-col
          v-if="hasPermission('view/chart-purchase-order')"
          lg="6"
        >
          <h3>Purchase Order</h3>
          <b-row>
            <b-col sm="6">
              <label for="rangeDate">Select range date</label>
              <date-range-picker
                id="rangeDate"
                :date-range="vars.rangeDate"
                @update="updateDate"
              />
            </b-col>
            <b-col sm="6">
              <label for="rangeDate" />
              <v-select
                id="tag"
                v-model="config.additional_params.kfsId"
                clearable
                label="name"
                placeholder="- Pilih KFS -"
                :options="masters.kfs"
                :reduce="options => options.id"
              >
                <template #no-options="{ search, searching, loading }">
                  Tidak ditemukan hasil pencarian
                </template>
              </v-select>
            </b-col>
          </b-row>
          <!-- <AppEchartBar :option-data="po.option" /> -->
          <apexchart
            width="100%"
            type="area"
            :options="chartOptions"
            :series="series"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="infographic, index in masters.infographics"
          :key="index"
          md="4"
        >
          <b-card
            v-if="hasPermission(infographic.permission)"
            class="infographic border text-center rounded shadow"
          >
            <h5>{{ infographic.title }}</h5>
            <span class="display-4">{{ infographic.value }}</span>
            <p>{{ infographic.desc }}</p>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import DateRangePicker from '@/layouts/components/DateRangePicker.vue'
import api from '@/utils/api'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    DateRangePicker,
    vSelect,
  },
  metaInfo: {
    title: 'Dashboard',
  },
  data() {
    return {
      chartOptions: {
        noData: {
          text: 'No data found',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined,
          },
        },
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
            export: {
              csv: {
                filename: 'Purchase Order Chart',
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                },
              },
              svg: {
                filename: 'Purchase Order Chart',
              },
              png: {
                filename: 'Purchase Order Chart',
              },
            },
            autoSelected: 'zoom',
          },
        },
        // colors: '#B22A2E',
        colors: ['#B22A2E'],
        fill: {
          colors: '#B22A2E',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val, opts) {
            return `${val.toLocaleString().replace(/[.,]/g, m => (m === '.' ? ',' : '.'))}`
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: value =>
              // Nine Zeroes for Billions
              (Math.abs(Number(value)) >= 1.0e+9
                ? `${(Math.abs(Number(value)) / 1.0e+9).toFixed(2)} Miliar`
              // Six Zeroes for Millions
                : Math.abs(Number(value)) >= 1.0e+6
                  ? `${(Math.abs(Number(value)) / 1.0e+6).toFixed(2)} Juta`
                // Three Zeroes for Thousands
                  : Math.abs(Number(value)) >= 1.0e+3
                    ? `${(Math.abs(Number(value)) / 1.0e+3).toFixed(2)} Ribu`
                    : Math.abs(Number(value)))
            ,
          },

        },

      },
      series: [{
        name: 'Sales',
        data: [],
      }],
      vars: {
        rangeDate: {
          startDate: null,
          endDate: null,
        },
      },
      config: {
        additional_params: {
          startDate: null,
          endDate: null,
          kfsId: null,
        },
      },
      masters: {
        kfs: [],
        infographics: [
          {
            title: 'Permintaan Ubah Invoice',
            value: 0,
            desc: 'Invoice',
            permission: 'view/card-invoice',
          },
          {
            title: 'Permintaan Purhcase Order',
            value: 0,
            desc: 'Permintaan',
            permission: 'view/card-purchase-order',
          },
          {
            title: 'Jumlah Mitra',
            value: 0,
            desc: 'Mitra',
            permission: 'view/card-mitra',
          },
        ],
      },
    }
  },
  computed: {
    kfsId() {
      return this.config.additional_params.kfsId
    },
  },
  watch: {
    kfsId() {
      this.getChart()
    },
  },
  mounted() {
    if (this.hasPermission('view/card-invoice')) {
      // this.$axios.get(api.changeInvoice).then(res => {
      //   this.masters.infographics[0].value = res.data.data
      // })
    }
    if (this.hasPermission('view/card-purchase-order')) {
      this.$axios.get(api.saleCount).then(res => {
        this.masters.infographics[1].value = res.data.data
      })
    }
    if (this.hasPermission('view/card-mitra')) {
      this.$axios.get(api.mitraCount).then(res => {
        this.masters.infographics[2].value = res.data.data
      })
    }
    if (this.hasPermission('view/chart-sales-order')) {
      this.getChart()
    }
    this.$axios.get(`${api.list_kfs}`).then(res => {
      this.masters.kfs = [...res.data.data]
    })
  },
  methods: {
    updateDate(value) {
      const { startDate, endDate } = value
      this.config.additional_params.startDate = this.moment(startDate).format('YYYY-MM-DD')
      this.config.additional_params.endDate = this.moment(endDate).format('YYYY-MM-DD')

      this.getChart()
    },

    getChart() {
      this.$axios.get(api.chartSale, { params: this.config.additional_params }).then(res => {
        const { values } = res.data.data
        const series = []
        const categories = []
        values.forEach(value => {
          categories.push(value.period)
          series.push(value.value)
        })
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories,
            },
          },
        }
        this.series = [{
          data: series,
        }]
      })
    },
  },
}
</script>

<style>

</style>
